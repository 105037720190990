import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home.js';
import Projects from './Projects.js';

import './App.css';

const App = () => {
  const canvas = document.getElementById('noise');
  window.mobileCheck() ? canvas.style.height = " 150%" : canvas.style.height = "100%";
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recounts" element={<Projects />} />
          <Route path="*" element={<div>404 - Not found</div>} />
        </Routes>
      </div>
    </Router>
  );
}



export default App;
