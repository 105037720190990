import React from "react";
import './RecountGrid.css'; // Optional, for styling
import machinegod from './machinegod.png';

const RecountGrid = () => {
  const items = [
    { img: machinegod, link: 'https://open.spotify.com/album/2hui6wgFuUP84KUWxNE8z6?si=7vsN0PlUQUCo8XnbhH0SXQ', text: `<span style="font-weight: bold;">Divinity:</span> Seraphim <br> <span style="font-weight: bold;">Status:</span> MAD (Dismantled) <br> <span style="font-weight: bold;">Description:</span> This project was undertaken by a joint effort between the nordic countries. It began shortly after the initial signing of the Artificial Divinity Protocol in September 2003 and finished on December 25th, 2007. Microactivations (activation time of one microsecond or less) of the seraphim that was titled "Maskingud" or "The Machine God" were deemed to have positive intent at the time. Unfortunately, the full activation of the seraphim demonstrated its intentions to be false positives. Within a millisecond of activation half of Sweden was leveled destroying every possible structure and filling in every possible gap, and after a full second all of the nordic countries were devastated resulting in an estimated 14 million casualties. A manual override stationed in Germany was quickly triggered by associated NOMAD agents reducing further damages. It is hypothesized that the seraphim found human separation and architecture to be a limiting factor in our development and took a drastic first step into resolving that issue. `},
  ];

  return (
    <div className="grid-container">
      {items.map((item, index) => (
        <div key={index} className="grid-item">
          <div className="image-box">
            <a target="_blank" href={item.link}><img  src={item.img} alt={`Recount ${index + 1}`} /></a>
            
          </div>
          <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
        </div>
      ))}
    </div>
  );
};

export default RecountGrid;
